import { getAuth, postAuth } from '../helper/axios.js'
import { generateLoginCode } from '../helper/util.js'

const Login = {
    getLogin: async () => {
        
        try {
            const loginCode = generateLoginCode();
            const response = await getAuth(`auth/login`, loginCode.codeChallenge);
            if (response.status != 200) throw (response);//expected error

            const retJson = {
                codeVerifier: loginCode.codeVerifier,
                redirectUrl: response.data,
                success: true
            };

            return retJson;

        } catch (error) {
            console.error(error);
            return error;
        }
        
    },
    postAuthCallback: async (codeVerifier, authorizationCode) => {
        try {
                
            const headers = {
                codeverifier : codeVerifier,
                authorizationcode: authorizationCode
            }
            const response = await postAuth(`auth/callback`, headers)
            if (response.status != 200) throw (response);//expected error
            const data = tryParse(response.data);
            const body = tryParse(data.body);

            const retJson = {
                token: body.token,
                img: body.img,
                userinfo: body.userinfo,
                success: true
            };
            return retJson;
            
        } catch (error) {
            console.error(error);
            return error;
        }
    }
};

function tryParse(str) {
    try {
        return JSON.parse(str);        
    } catch (error) {
        return str;
    }
}

export default Login;
