import axios from "axios";
const apiPath =  process.env.REACT_APP_API_URL || '/api/'//AZURE LINKED API; //"http://localhost:7071/api/";
var token = localStorage.getItem('signed_token')
const token_key = JSON.parse(token)

const sendRequest = async (method, api, req) => {
    sessionStorage.setItem('historyUrl', window.location.pathname);
    let headers = {};
    headers['Authorization'] = `Bearer ${token_key.token}` || req;
    try {

        const response = await axios({
            method,
            url: `${apiPath}${api}`,
            headers,
            data: req
        })
        .then((res) => res)
        .catch((error) => {
            if(error.response && error.response.status){
                switch(error.response.status){
                    case 404:
                        const res = {data:"Not Found",status:404,statusText:"Not Found"}
                        return {data:res};
                    case 500:
                    case 401:
                        localStorage.clear();
                        window.location.href = '/login';
                }
            }

            if(error.code == "ERR_NETWORK"){
                console.log("Network error :", error);
                throw "Network error";
            }
            throw new Error(error.response.data);
        });
        
        
        return response;
    } catch (error) {
        throw new Error(error);
    }
};

const sendRequestAuth = (method, api, req) => {    
    let headers = {};
    if (req.codeverifier) //used by auth callback
        headers['codeverifier'] = req.codeverifier;

    headers['requestoruri'] = window.location.origin;
    
    headers['authorizationcode'] = req.authorizationcode || req;

    const config = {
        method,
        url: `${apiPath}${api}`,
        headers : headers,
        data : req
    };
    if(method === 'get') delete config.data;
    return axios(config)
        .then((res) => res)
        .catch((error) => {
            throw error;
        });

 
};

export const get = (api, req) => sendRequest('get', api, req);
export const post = (api, req) => sendRequest('post', api, req);
export const getAuth = (api, req) => sendRequestAuth('get', api, req);
export const postAuth = (api, req) => sendRequestAuth('post', api, req);
