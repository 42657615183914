import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutes = ({token}) => {
    if (token.token === null || token.token === undefined || token.token === '') {
        return <Navigate to="/login" />
    }

    // console.log('roles:',token);
    const decode = JSON.parse(atob(token.token.split('.')[1]));
    const roles = decode['role'];
    const currentURL = window.location.pathname;

    const hasAccess = roles.some(role => role.module_path === currentURL);
    if (hasAccess) {
        return <Outlet />
    }else{
        switch(currentURL){
            case '/role':
            case '/user':
            case '/dashboard':
            case '/':
                return <Outlet />
            default:
                // redirect to unauthorized page if user does not have access to the current page
                return <Navigate to="/unauthorized" override={true} />
        }
    }
    
    
    // return <Outlet />
}

export default PrivateRoutes